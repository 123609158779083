import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MazayaTermsAndConditionsResponseModel, RQMazayaTermsAndConditionsModel, TermsAndConditionsModel } from '../models/terms-and-conditions/terms-and-conditions';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionService {

  private resource = `${environment.serverUrl}/adnoctermsandconditions`;
private mazayaResource=`${environment.serverUrl}/mazayaTermsAndConditions`
  constructor(private http: HttpClient) { }

  getGlobalTermsAndConditions(): Observable<TermsAndConditionsModel> {
    const url = `${this.resource}/global`;
    return this.http.get<TermsAndConditionsModel>(url);
  }

  getTermsAndConditionForOffer(): Observable<TermsAndConditionsModel> {
    const url = `${this.resource}/offer`;
    return this.http.get<TermsAndConditionsModel>(url);
  }

  getTermsAndConditionForProposal(): Observable<TermsAndConditionsModel> {
    const url = `${this.resource}/proposal`;
    return this.http.get<TermsAndConditionsModel>(url);
  }

  updateTermsAndConditions(termsAndConditions: TermsAndConditionsModel): Observable<TermsAndConditionsModel> {
    const url = `${this.resource}`;
    termsAndConditions.content = btoa(unescape(encodeURIComponent(termsAndConditions.content)));
    termsAndConditions.contentArabic = btoa(unescape(encodeURIComponent(termsAndConditions.contentArabic)));

    return this.http.post<TermsAndConditionsModel>(url, termsAndConditions);
  }

  createMazayaTermsAndCondition(termsAndConditions:RQMazayaTermsAndConditionsModel): Observable<any> {
    const url = `${this.mazayaResource}/createTermsAndConditions`;
    termsAndConditions.content = btoa(unescape(encodeURIComponent(termsAndConditions.content)));
    return this.http.post<any>(url,termsAndConditions);
  }
  getSupplierTermsAndCondition(): Observable<MazayaTermsAndConditionsResponseModel> {
    const url = `${this.mazayaResource}/getTermsConditionsSupplier`;
    return this.http.get<MazayaTermsAndConditionsResponseModel>(url);
  }
  getBuyerTermsAndCondition(): Observable<MazayaTermsAndConditionsResponseModel> {
    const url = `${this.mazayaResource}/getTermsConditionsBuyer`;
    return this.http.get<MazayaTermsAndConditionsResponseModel>(url);
  }

  updateMazayaTermsAndCondition(termsAndConditions:RQMazayaTermsAndConditionsModel): Observable<any> {
    const url = `${this.mazayaResource}/updateTermsConditions`;
    termsAndConditions.content = btoa(unescape(encodeURIComponent(termsAndConditions.content)));
    return this.http.post<any>(url,termsAndConditions);
  }
}
