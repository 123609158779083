export const environment = {
  production: true,
  //serverUrl:'https://api.mazayaoffers.ae/api',
 serverUrl: 'https://mazaya-api.gewaninfotech.com/api', 
   

  socketUrl: 'https://mazaya-api.gewaninfotech.com',
  clientUrl: 'https://mazaya-api.gewaninfotech.com',
  useSSO: false,
  instrumentationKey: null,
  defaultLanguage: 'en-US',
};

  