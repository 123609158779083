import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { YardService } from '../../services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  totalUsers: number = 0
  cardMazayaPlusPlus: number = 0
  cardMazayaPower: number = 0
  cardMazayaExplore: number = 0
  regMazaya: number = 0
  regMazayaPlusPlus: number = 0
  regMazayaPower: number = 0
  regMazayaExplore: number = 0
  upgradedUsers: number = 0
  expiredUsersWithoutRenewal: number = 0

  form: FormGroup;
  constructor(
    private _router: Router,
    private _yardService: YardService,
    private fb: FormBuilder
  ) {

  }
  ngOnInit() {
    this.initLoadDashboard();
        this.form = this.fb.group({
          startDate:[null],
          endDate: [null],
    });
  }
  initLoadDashboard() {
    this._yardService.getYardDashboardData().subscribe(res => {
      console.log(">>>>>>>>>>>>>", res)
      this.totalUsers = res.totalUsers
      this.cardMazayaPlusPlus = res.activeCards.mazayaPlusPlus
      this.cardMazayaPower = res.activeCards.mazayaPower
      this.cardMazayaExplore = res.activeCards.mazayaExplore
      this.regMazaya = res.newRegistrations.mazaya
      this.regMazayaPlusPlus = res.newRegistrations.mazayaPlusPlus
      this.regMazayaPower = res.newRegistrations.mazayaPower
      this.regMazayaExplore = res.newRegistrations.mazayaExplore
      this.upgradedUsers = res.upgradedUsers
      this.expiredUsersWithoutRenewal = res.expiredUsersWithoutRenewal

    })
  }
  onClickDetails() {
    this._router.navigate(['/yard-administration/dashboard/details'])
  }
}
