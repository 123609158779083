<div class="container-lg text-center dashboard-container">
    <div class="datepicker-container d-flex justify-content-end">
        <form [formGroup]="form" >
            <mat-form-field class="calender">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate">
                <mat-datepicker-toggle matIconSuffix
                    [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>End date</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate">
                <mat-datepicker-toggle matIconSuffix ></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </form>
        
        <div>
            search
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>
    </div>

    <div class="row ">
        <div class="col ">
            <div class="card" style="width: 18rem;" (click)="onClickDetails()">
                <div class="card-body">
                    <h5 class="card-title">Total Number of Users </h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{totalUsers}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;" (click)="onClickDetails()">
                <div class="card-body">
                    <h5 class="card-title">Active Mazaya++ Cards</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{cardMazayaPlusPlus}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Active Mazaya Power Cards</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{cardMazayaPower}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Active Mazaya Explore Cards</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{cardMazayaExplore}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">New Registrations (Mazaya)</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{regMazaya}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">New Registrations (Mazaya++)</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{regMazayaPlusPlus}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">New Registrations (Mazaya Power)</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{regMazayaPower}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">New Registrations (Mazaya Explore)</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{regMazayaExplore}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Upgraded Users</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{upgradedUsers}}
                    </p>

                </div>
            </div>
        </div>
        <div class="col">
            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Expired Users Without Renewal</h5>
                    <h6 class="card-subtitle mb-2 text-muted"></h6>
                    <p class="card-text">
                        {{expiredUsersWithoutRenewal}}
                    </p>

                </div>
            </div>
        </div>



    </div>




</div>