<div class="user-management-container">

    <div class="card">
        <div class="card-body">
            <div class="create-container">
                <div class="title center-container">
                    Edit Yard User
                </div>
                <div class="row mt-2 center-container">
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col">
                                <input type="email" class="form-control" placeholder="Name">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 center-container">
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col">
                                <input type="email" class="form-control" placeholder="Email">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 center-container reset">
                    <div class="col-sm-8">
                        <div class="row align-items-center">
                            <div class="col-sm-8">
                                <input type="password" class="form-control" placeholder="Password">
                            </div>
                            <div class="col-sm-2">
                                <button class="btn btn-reset" type="button">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 center-container">
                    <div class="col-sm-8">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                Enable multi-factor authentication
                            </label>
                        </div>
                    </div>

                </div>

                <div class="center-container mt-2">
                    <button type="button" class="btn btn-primary">Submit</button>
                </div>

            </div>
        </div>
    </div>
</div>