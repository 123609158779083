import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryModel } from 'src/app/shared/models';
@Injectable({ providedIn: 'root' })
export class YardService {

  private resource = `${environment.serverUrl}/Dashboard/`;


  constructor(private http: HttpClient) { }


  getYardDashboardData(): Observable<any> {
    return this.http.get<any>(`${this.resource}getnineyardsdashboard?startDate=2024-01-01&endDate=2024-01-31`);
  }


}
