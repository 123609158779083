import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from "@ngxs/store";
import { Auth } from 'src/app/core/security/auth';
import { LocalStorage } from 'src/app/core/security/local-storage';
import { AuthState } from "src/app/state";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {
  role: string;

  constructor(
    private _store: Store,
    private _router: Router
  ) { }

  ngOnInit(): void {
    var redirect_url = LocalStorage.getItem(Auth.REDIRECT_URL);
    if (redirect_url != null) {
      LocalStorage.removeItem(Auth.REDIRECT_URL);
      this._router.navigate([redirect_url])
    }
    this.initRole();
  }

  initRole(): void {
    this._store.select(AuthState.roles).subscribe((res) => {
      this.role = res[0];
      if (this.role == 'RoadshowFocalPoint')
        this._router.navigate([`roadshows-administration`]);
      if (this.role == 'NineYardsAdmin')
        this._router.navigate([`yard-administration`]);
    });
  }
}
