import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LoginService } from 'src/app/core/services';
import { MazayaEcardAdminUpdate, MazayaEcardDetail } from 'src/app/shared/models/mazaya-plus/membership-details-model';
import { AcademySuppliermodel, UpdateAcademyScanlimitModel } from 'src/app/shared/models/mazaya-plus/voucher-model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

interface Relation {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-card-update',
  templateUrl: './card-update.component.html',
  styleUrls: ['./card-update.component.scss']
})
export class CardUpdateComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  isEnableModal: boolean = false;
  email: string = "";
  cardNo: string = "";
  name: string = "";
  membershipDetails: MazayaEcardDetail[]
  selectedItem: MazayaEcardDetail
  limitCount: number = 0;
  isVisibleCounter: boolean = false
  isHideAddMore: boolean = false;
  myForm: FormGroup;
  profile_image: string = null;
  selectedImage: File | null = null;
  selectedCard: number;
  fileWithInfo: File;
  userCardView: boolean = false;
  increaseLimitModal: boolean = false;
  increaseAcademyLimitModal: boolean = false;
  isAcademySave: boolean = false;
  maxDate: Date = this.calculateMaxDate();
  today: Date = new Date();
  searchEmail: string = "";
  selectedEmail: string = "";
  academySupplier: AcademySuppliermodel[] = []

  relations: Relation[] = [
    { value: 'Father', viewValue: 'Father' },
    { value: 'Mother', viewValue: 'Mother' },
    { value: 'Son', viewValue: 'Son' },
    { value: 'Daughter', viewValue: 'Daughter' },
    { value: 'Spouse', viewValue: 'Spouse' },
    { value: 'Other', viewValue: 'Other' },
  ];
  isEnableVoucher: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private _adminService: AdminService,
    private renderer: Renderer2,
    private loginService: LoginService,
    private _notificationService: NotificationsService,
    private _router: Router
  ) {
    this.myForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.email],
      dob: [''],
      relation: [''],
      userGender: ['', Validators.required]
    });

  }

  calculateMaxDate(): Date {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setFullYear(today.getFullYear() - 18);
    return maxDate;
  }

  onEdit(i) {
    this.selectedCard = i;
    this.isEnableModal = true
    const date = moment(this.membershipDetails[i].dob);
    const formattedDob = date.format('YYYY-MM-DD');

    this.profile_image = this.membershipDetails[i].profile_image;
    this.myForm = this.formBuilder.group({
      firstname: [this.membershipDetails[i].firstname, Validators.required],
      lastname: [this.membershipDetails[i].lastname, Validators.required],
      email: [this.membershipDetails[i].email, [Validators.required, Validators.email]],
      relation: [this.membershipDetails[i].relation, Validators.required],
      dob: [formattedDob, Validators.required],
      userGender: [this.membershipDetails[i].gender, Validators.required]
    });
  }
  onSearch() {
    this.searchEmail = this.email.trim();
    const searchCardNo = this.cardNo.trim();
    const searchName = this.name.trim();
    if (this.searchEmail != "" || searchCardNo != "" || searchName != "") {
      const rqBody: { username: string, cardnumber: string, name: string } =
      {
        username: this.searchEmail,
        cardnumber: searchCardNo,
        name: searchName

      }
      this._adminService.getMazayaCard(rqBody).subscribe((res) => {
        this.membershipDetails = res.data
      })
    }

  }
  encodeString(value: string): string {
    return encodeURIComponent(value).replace(/%2B/g, '+');
  }
  onCloseEdit() {
    this.profile_image = null;
    this.isEnableModal = false;
    this.selectedCard = 0;
    this.selectedImage = null;

  }

  selectFile(): void {
    this.renderer.selectRootElement(this.fileInput.nativeElement).click();
  }
  onFileSelected(event: any): void {
    this.selectedImage = event.target.files[0];
    this.showPreview();

  }

  showPreview() {
    if (this.selectedImage) {

      const reader = new FileReader();
      reader.onload = (e) => {
        this.profile_image = reader.result as string;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }

  onUpdateDetails() {
    if (this.myForm.valid) {
      if (this.selectedImage != null) {
        this.loginService.saveTradeLicence(this.selectedImage).subscribe(
          (res) => {
            const formValues = this.myForm.value;
            const date = moment(formValues.dob);
            const formattedDob = date.format('YYYY-MM-DD');

            const request: MazayaEcardAdminUpdate = {
              id: this.membershipDetails[this.selectedCard].id,
              firstname: formValues.firstname,
              lastname: formValues.lastname,
              relation: formValues.relation,
              dob: formattedDob,
              email: formValues.email,
              profile_image: res.id,
              gender: formValues.userGender,
              status: this.membershipDetails[this.selectedCard].status
            }


            this._adminService.updateEcard(request)
              .subscribe((res) => {

                if (res.data === "Success") {
                  this.onSearch();
                  this.onCloseEdit();
                  this._notificationService.success(
                    "Success",
                    "Successfully uploaded"
                  );
                }
              })
          }

        )
      } else {
        const formValues = this.myForm.value;
        const date = moment(formValues.dob);
        const formattedDob = date.format('YYYY-MM-DD');
        let imageId = null;
        const imageUrl = this.membershipDetails[this.selectedCard].profile_image
        if (imageUrl !== null) {
          const parts = imageUrl.split('documents/');

          if (parts.length > 1) {
            imageId = parts[1];

          } else {
            imageId = null
          }
        }
        const request: MazayaEcardAdminUpdate = {
          id: this.membershipDetails[this.selectedCard].id,
          firstname: formValues.firstname,
          lastname: formValues.lastname,
          relation: formValues.relation,
          dob: formattedDob,
          email: formValues.email,
          profile_image: imageId,
          gender: formValues.userGender,
          status: this.membershipDetails[this.selectedCard].status
        }


        this._adminService.updateEcard(request)
          .subscribe((res) => {
            if (res.data === "Success") {
              this.onSearch();
              this.onCloseEdit();
              this._notificationService.success(
                "Success",
                "Successfully uploaded"
              );
            }
          })
      }

    } else {
      this._notificationService.error(
        "Error",
        "Please enter required fields"
      );
    }
  }
  onViewCardList(i) {
    this.selectedEmail = this.membershipDetails[i].email;
    this.userCardView = true;
  }
  goBack() {
    this.userCardView = false;
  }
  resendInvite(email: string) {
    this._adminService.resendInvite(email)
      .subscribe((res) => {
        this._notificationService.success(
          "Invited",
          "Successfully Invited"
        );
      })
  }
  viewVoucher(id: string, userId: string, email: string, gender: string, isAdultMember: boolean) {
    if (gender === null) {
      this._notificationService.error(
        "Gender missing",
        "Please update the gender first"
      );
    } else {
      if (userId === "User doesn't exists." || userId === "")
        userId = '0'
      this._router.navigate([`/card-update/voucher/${id}/${userId}/${isAdultMember}`])
    }

  }

  increaseQrLimit(item: MazayaEcardDetail) {

    this.selectedItem = item
    this.increaseLimitModal = true;
    this.limitCount = item.ecardclaimLimitCount
    if (item.ecardclaimLimitCount !== 0) {
      this.isVisibleCounter = true;
      this.isHideAddMore = true;
    }


  }

  decreaseLimit() {
    if (this.limitCount != 0)
      this.limitCount--
  }
  increaseLimit() {
    this.limitCount++
  }
  addMore() {
    this.isVisibleCounter = !this.isVisibleCounter
  }
  updateQrScanLimit() {
    const rqBody: { ecarddetailsid: number, supplierclaimlimitforQR: number, limit: number } = {
      ecarddetailsid: this.selectedItem.id,
      supplierclaimlimitforQR: this.selectedItem.claimLimitForQR,
      limit: this.limitCount
    }
    this._adminService.updateQrScanlimit(rqBody).subscribe((res) => {
      this.closeLimitModal()
      this.onSearch()
    })
  }
  findLimitType(value: number) {
    if (value === 1) {
      return "Unlimited"
    }
    else if (value === 2) {
      return "Year"
    }
    else if (value === 3) {
      return "Quarter"
    }
    else if (value === 4) {
      return "Month"
    }
    else if (value === 5) {
      return "Day"
    }
  }
  closeLimitModal() {
    this.increaseLimitModal = false
    this.isVisibleCounter = false;
    this.limitCount = 0
    this.selectedItem = null
    this.isHideAddMore = false;
  }

  increaseAcademyLimit(item: MazayaEcardDetail) {
    this.selectedItem = item
    this.isAcademySave=false;
    const rqBody: { ecarddetailsId: number, ecardmainId: number } = {
      ecarddetailsId: item.id,
      ecardmainId: item.mazayaEcardmainId

    }
    this._adminService.getSupplierForAcademyScanLimit(rqBody).subscribe(
      (res: any) => {
        this.increaseAcademyLimitModal = true;
        this.academySupplier = res.suppliers;
        this.rearrangeArray();
      })
  }
  rearrangeArray() {
    this.academySupplier.sort((a, b) => {
      if (a.claimLimitForQR === 1 && b.claimLimitForQR !== 1) {
        return -1;
      } else if (a.claimLimitForQR !== 1 && b.claimLimitForQR === 1) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  saveAcademicLimit() {
    this.isAcademySave = true;
  }
  academyCallback(data: AcademySuppliermodel[]) {
    const rqBody: UpdateAcademyScanlimitModel[] = []
    data.forEach(el => {
      console.log("WWWWWW", el)
      const temp: UpdateAcademyScanlimitModel = {
        MazayaSupplierConfigId: el.supplierId,
        AcademyScanLimitType: el.academyScanLimitType,
        ClaimLimitForQR: el.claimLimitForQR,
        AcademyScanLimitCount: el.academyScanLimitType,
        MazayaEcardmainId: this.selectedItem.mazayaEcardmainId,
        MazayaEcarddetailsId: this.selectedItem.id,
        AdditionalLimitCount: el.additionalLimitCount
      }
      rqBody.push(temp)
    });
    this._adminService.updateAcademyScanLimitForVip(rqBody).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this._notificationService.success(
            "",
            res.userMessage
          );
        }
        this.increaseAcademyLimitModal=false
        this.selectedItem = null
        this.isAcademySave=false;
      })
  }
}
