<div class="user-management-container">
    <div class="row">
        <div class="col-sm-8 ">
            <div class="title">
                User Management
            </div>
            <div class="sub-title">
                20 users
            </div>
        </div>
        <div class="col-sm-4 ">

        </div>

    </div>


    <div class="search-container">
        <div class="row">
            <div class="col-sm-8">

                <div class="row">
                    <div class="col">
                        <input type="email" class="form-control" placeholder="Name">
                    </div>
                    <div class="col">
                        <input type="email" class="form-control" placeholder="Email">
                    </div>

                </div>
            </div>
            <div class="col-sm-2">
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary">Search</button>
                </div>


            </div>
            <div class="col-sm-2">

                <div class="d-flex justify-content-start">
                    <button type="button" class="btn btn-grey">Clear</button>
                </div>
            </div>


        </div>
    </div>
    <table class="table table-hover table-bordered">
        <thead>
            <tr>
                <th scope="col" *ngFor="let heading of tableHeading">{{heading}}</th>
            </tr>
        </thead>
        <tbody *ngFor="let user of usersList; let i=index ">
            <tr>
                <th scope="row">{{i+1}}</th>
                <td class="action-column">Mark</td>
                <td class="action-column">Markdffff@gmail.com</td>
                <td class="action-column">Otto</td>
                <td class="action-column">Otto</td>
                <td class="action-column">Yes</td>
            </tr>


        </tbody>

    </table>
    <div class="footer">
        <pagination [totalCount]="80" [pageSize]="15" [currentPage]="1">
        </pagination>
    </div>

</div>

