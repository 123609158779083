import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-deatils',
  templateUrl: './dashboard-deatils.component.html',
  styleUrls: ['./dashboard-deatils.component.scss']
})
export class DashboardDeatilsComponent {
  usersList: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  tableHeading: string[]=[
    "SI No.",
    "Name ",
    "E-Mail ID",
    "Mobile Number",
    "Date of account creation",
    "Whether the user have an active Mazaya premium membership card"
  ]
}
