import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CardUpdateComponent } from './pages/card-update/card-update.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { NewYardUserComponent } from './pages/user-management/new-yard-user/new-yard-user.component';
import { DashboardDeatilsComponent } from './pages/dashboard/dashboard-deatils/dashboard-deatils.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "dashboard/details",
        component: DashboardDeatilsComponent,
      },
      {
        path: "card-update",
        component: CardUpdateComponent,
      },
      {
        path: "user-management",
        component: UserManagementComponent,
      },
      {
        path: "user-management/new",
        component: NewYardUserComponent,
      }
    ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class YardsAdministrationRoutingModule { }
