import { Component } from '@angular/core';

@Component({
  selector: 'app-new-yard-user',
  templateUrl: './new-yard-user.component.html',
  styleUrls: ['./new-yard-user.component.scss']
})
export class NewYardUserComponent {

}
